export default {
  symbol: {
    layout: {
      'icon-size': 1.2,
      'icon-padding': 0,
      'icon-allow-overlap': true,
      'icon-image': 'marker-11'
    },
    paint: {}
  },
  line: {
    layout: {},
    paint: {}
  },
  fill: {
    layout: {},
    paint: {
      'fill-color': [
        'step',
        ['get', 'pourcentage'],
        '#ffeda0',
        0,
        '#fed976',
        0.002,
        '#feb24c',
        0.004,
        '#fd8d3c',
        0.006,
        '#fc4e2a',
        0.008,
        '#e31a1c',
        0.01,
        'hsl(348, 100%, 37%)',
        2,
        '#bd0026'
      ],
      'fill-opacity': 0.8,
      'fill-antialias': true
    }
  },
  circle: {
    layout: {},
    paint: {
      'circle-radius': 4,
      'circle-opacity': 0.8,
      'circle-color': '#09569B'
    }
  },
  heatmap: {
    layout: {},
    paint: {}
  }
}
