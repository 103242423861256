<template>
  <div class="radius-selector">
    <div v-if="isActive">
      <radius-selector-header />
      <slider-wrapper>
        <slider
          v-model="sliderValue"
          class="circle-filter-tool-slider"
          type="range"
          :min="0.5"
          :max="3"
          :step="0.1"
          :colors="['#3d5bf3', '#dfdfdf']"
          :thumb-color="'#3d5bf3'"
        />
      </slider-wrapper>
    </div>
  </div>
</template>

<script>
import Slider from './commons/Slider.vue'
import RadiusSelectorHeader from './layout/radius-selector-header.vue'
import SliderWrapper from './layout/slider-wrapper.vue'
export default {
  components: { Slider, RadiusSelectorHeader, SliderWrapper },
  props: {
    radius: {
      type: Number,
      default: 1
    },
    active: {
    }
  },
  computed: {
    sliderValue: {
      get () {
        return this.radius
      },
      set (value) {
        this.$emit('update:radius', value)
      }
    },
    isActive: {
      get () {
        return this.active
      },
      set (value) {
        this.$emit('update:active', value)
      }
    }
  },
  data () {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.radius-selector {
  padding: 0
}
</style>
