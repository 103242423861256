<template>
  <div class="slider-container">
    <input
      :title="value + ' km'"
      class="slider"
      :style="{
        background,
        '--tumb-background': thumbColor
      }"
      type="range"
      :min="min"
      :max="max"
      :step="step"
      :value="value"
      @input="onInput($event.target)"
    />
  </div>
</template>

<script>
export default {
  props: {
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    step: {
      type: Number,
      default: 1
    },
    value: {
      type: Number,
      default: 50
    },
    colors: {
      type: Array,
      default: () => ['#5b5b5b', '#dddddd']
    },
    thumbColor: {
      type: String,
      default: 'blue'
    }
  },
  computed: {
    background () {
      return this.buildValueGradient()
    }
  },
  methods: {
    onInput (el) {
      this.$emit('input', parseFloat(el.value))
    },
    buildValueGradient () {
      const totalLength = this.max - this.min
      const valueLength = this.value - this.min
      const valueStep = (valueLength / totalLength) * 100
      return `linear-gradient(to right, ${this.colors[0]} 0%, ${this.colors[0]} ${valueStep}%, ${this.colors[1]} ${valueStep}% 100%`
    }
  }
}
</script>

<style lang="scss" scoped>
.slider-container {
  position: relative;
  width: 100%;
}
.slider {
  position: relative;
  --tumb-background: #ffffff;
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 3px;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  opacity: 1;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #dddddd;
    border-radius: 50%;
    background: var(--tumb-background);
    cursor: pointer;
  }
}

.slider:hover {
  opacity: 1;
}

.slider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #dddddd;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
}
</style>
