import axios from 'axios'
export default class ApiClient {
  constructor (token, baseUrl) {
    this.headers = {
      Authorization: 'Bearer ' + token,
      'content-type': 'application/json'
    }
    this.baseUrl = baseUrl
  }

  async get (endpoint, params) {
    const headers = this.headers
    try {
      const response = await axios.get(this.baseUrl + endpoint, {
        headers,
        params
      })
      return response.data
    } catch (err) {
      console.log('err in backend client get method ===>', err.response.data)
      alert('something went wrong. Please try again later !')
      throw err
    }
  }

  async post (endpoint, body) {
    const headers = this.headers
    try {
      const response = await axios.post(this.baseUrl + endpoint, body, { headers })
      return response.data
    } catch (err) {
      console.log('err in backend client post method ===>', err.response.data)
      alert('something went wrong. Please try again later !')
      throw err
    }
  }
}
