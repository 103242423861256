<template>
  <div class="circle-filter-tool">
    <div class="circle-filter-tool-label">500m</div>
    <slot>
      <slider
        value="1"
        class="circle-filter-tool-slider"
        type="range"
        :min="0.5"
        :max="3"
        :step="0.1"
        :colors="['#3d5bf3', '#dfdfdf']"
        :thumb-color="'#3d5bf3'"
      />
    </slot>
    <div class="circle-filter-tool-label">3km</div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.circle-filter-tool {
  &-label {
    font-size: 12px;
    font-weight: 500;
    line-height: 2.83;
    color: #808080;
  }
  &-slider {
    margin: 0 12px;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
</style>
