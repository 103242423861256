import axios from 'axios'
import Client from '../../lib/geodb-service-client'
export default {
  namespaced: true,
  state: {
    ages: null,
    population: null,
    density: null,
    pois: null,
    dataReady: false
  },
  getters: {
    population: state => {
      return state.population.population_2016
    },
    ages: state => {
      const formatKey = key => key.replace(/from_(.*?)_and_over/, 'plus de $1').replace('from_', 'de ').replace('_to_', ' à ') + ' ans'
      const total = Object.keys(state.ages).reduce((acc, cur) => {
        acc += state.ages[cur]
        return acc
      }, 0)
      return Object.keys(state.ages).map(key => ({
        id: key,
        name: formatKey(key),
        value: Math.round(state.ages[key] / total * 100)
      }))
    },
    density: state => {
      return state.density.hab_par_km2
    },
    pois: state => {
      const formatKey = key => {
        const labels = {
          count_supermarket: 'Épiceries',
          count_bakery: 'Boulangeries',
          count_saq: 'SAQ',
          count_school: 'écoles'
        }
        return labels[key]
      }
      return Object.keys(state.pois).map(key => ({
        id: key,
        name: formatKey(key),
        value: state.pois[key]
      }))
    },
    dataReady: state => state.dataReady
  },
  mutations: {
    update (state, { prop, payload }) {
      state[prop] = payload
    }
  },
  actions: {
    async updateData ({ commit, rootState }, { radius, center }) {
      const client = new Client(rootState.auth.token)
      const sqlFunctions = {
        age_repartition_2016: 'ages',
        densite_de_pop_2016: 'density',
        poi_osm: 'pois',
        population_2016: 'population'
      }
      const { lat: latitude, lng: longitude } = center
      const sqlFunctionsParams = Object.keys(sqlFunctions).map(f => ({
        sqlFunction: f,
        params: { radius: radius * 1000, latitude, longitude }
      }))
      const sqlFunctionsResults = process.env.VUE_APP_MOCKED_DATA
        ? await axios.get('mocked-data.json').then(res => res.data)
        : await client.fetchStats({ requests: sqlFunctionsParams })
      for (const f in sqlFunctions) {
        const data = sqlFunctionsResults.find(res => res.sqlFunction === f)
        if (data) {
          commit('update', { prop: sqlFunctions[f], payload: data.result })
        }
      }
      commit('update', { prop: 'dataReady', payload: true })
    }
  }
}
