<template>
  <div class="header">
    <div class="header-description">
      <h3>Rayon d'analyse</h3>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.header {
  &-heading {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: 600;
    color: #444444;
    width: 255px;
    label {
      font-size: 12px;
      letter-spacing: 0.5px;
      color: #b9b9b9;
    }
    &-select {
      font-size: 18px;
      font-weight: 600;
      border: none;
    }
  }
  &-description {
    margin: 4px 0;
    font-size: 14px;
    line-height: 1.36;
  }
}
</style>
