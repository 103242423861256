<template>
  <div class="bottom-panel">
    <div class="container">
      <slot />
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.bottom-panel {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 45%;
  z-index: 1000;
  overflow: auto;
  background-color: white;
  .container {
    position: relative;
    margin: 16px;
    /* background: blue; */
    /* height: 400px; */
  }
}
</style>
