<template>
  <div class="main">
    <left-panel class="panel-lg-screen" v-if="mapReady">
      <template v-slot:header>
        <h2>Contexte sociodémographique des franchises</h2>
        choisissez une franchise pour explorer la démographie avoisinante.
        Ajustez le rayon d'analyse.
      </template>
      <template v-slot:main>
        <div class="panel-content">
          <h3>Franchises</h3>
          <v-select
            style="border: solid 1px #dfdfdf; width: 320px"
            :options="provigoOptions"
            :selected.sync="selected"
          />
          <br />
        </div>
        <div class="panel-content" v-if="radiusToolActive">
          <div class="panel-content-element">
            <radius-selector
              :radius.sync="circleSelection.radius"
              :active.sync="radiusToolActive"
            />
          </div>
        </div>
        <div class="panel-content density-legend">
          <h4>Légende</h4>
          <div class="legend__widget">
            <chloropleth-legend :items="palette" />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        Une réalisation
        <a
          style="color: black; font-weight: 600"
          href="https://anagraph.io"
          target="_blank"
          >Anagraph</a
        >
      </template>
    </left-panel>
    <right-panel class="panel-lg-screen">
      <div class="panel-content-element">
        <selection-stats v-if="circleSelection.center" />
        <div v-else>
          sélectionnez une franchise pour voir les informations démographiques
        </div>
      </div>
    </right-panel>
    <right-panel class="panel-sm-screen">
      <a href="https://anagraph.io">
        <i class="fas fa-info-circle"></i>
      </a>
    </right-panel>
    <bottom-panel class="panel-sm-screen" v-if="mapReady">
      <div class="panel-content" v-if="radiusToolActive">
        Franchise: {{ selectedFeature.label }}
        <div style="font-weight: bold; text-decoration: underline; cursor: pointer" @click="itemSelected = null">
        voir toutes
        </div>
        <div class="panel-content-element">
          <radius-selector
            :radius.sync="circleSelection.radius"
            :active.sync="radiusToolActive"
          />
        </div>
      </div>
      <div class="panel-content-element">
        <selection-stats v-if="circleSelection.center" />
        <div v-else>
          <h2>Contexte sociodémographique des franchises</h2>
        choisissez une franchise pour explorer la démographie avoisinante.
        Ajustez le rayon d'analyse.
        <br>
        <br>
        </div>
        Une réalisation
        <a
          style="color: black; font-weight: 600"
          href="https://anagraph.io"
          target="_blank"
          >Anagraph</a
        >
      </div>
    </bottom-panel>
    <mapbox-map
      ref="mapContainer"
      @loaded="mapLoaded"
      :defaultStyles="defaultStyles"
      :layerStyles="layerStyles"
      :sources="layers"
      :visibleLayers="['densite-de-population']"
    />
  </div>
</template>

<script>
import { bbox as turfBbox } from '@turf/turf'
import RadiusSelector from '../components/radius-selector.vue'
import MapboxMap from '../components/commons/map.vue'
import LeftPanel from '../components/layout/left-panel.vue'
import BottomPanel from '../components/layout/bottom-panel.vue'
import CircleSelectionLayer from '../lib/CircleSelection'
import SelectionStats from '../components/SelectionStats.vue'
import RightPanel from '../components/layout/right-panel.vue'
import layers from '@/config/layers'
import defaultStyles from '@/config/defaults'
import layerStyles from '@/config/styles'
import VSelect from '../components/commons/Select.vue'
import palette from '@/config/populationPalette.json'
import ChloroplethLegend from '../components/commons/chloropleth-legend.vue'

export default {
  name: 'Home',
  components: {
    LeftPanel,
    MapboxMap,
    RadiusSelector,
    SelectionStats,
    RightPanel,
    VSelect,
    ChloroplethLegend,
    BottomPanel
  },
  created () {
    this.circleSelectionLayer = null
  },
  data () {
    return {
      mapReady: false,
      layers,
      palette,
      defaultStyles,
      layerStyles,
      radiusToolActive: false,
      circleSelection: {
        center: null,
        radius: 1,
        zoom: 14
      },
      itemSelected: null
    }
  },
  computed: {
    provigoSource () {
      return this.layers.find(l => l.id === 'provigo').geojson
    },
    provigoFeatures () {
      return this.provigoSource.features
    },
    provigoOptions () {
      const options = this.provigoFeatures.map(f => ({
        label: f.properties.name,
        id: f.properties.id
      }))
      return [{ id: null, label: 'Toutes les franchises' }, ...options]
    },
    selected: {
      get () {
        return this.itemSelected
      },
      set (value) {
        this.itemSelected = value
      }
    },
    selectedFeature () {
      return this.provigoOptions.find(f => f.id === this.itemSelected)
    }
  },
  watch: {
    async radiusToolActive (value) {
      if (this.circleSelectionLayer) {
        await this.circleSelectionLayer.toggle(value)
      }
    },
    circleSelection: {
      deep: true,
      async handler ({ center, radius }) {
        if (this.circleSelection.center && this.radiusToolActive) {
          this.circleSelectionLayer.set({ center, radius })
          this.$store.dispatch('selectionStats/updateData', { center, radius })
        }
      }
    },
    itemSelected (id) {
      if (id) {
        this.select(id)
      } else {
        this.circleSelection.center = null
        this.radiusToolActive = false
        this.zoomInit()
      }
    }
  },
  methods: {
    mapLoaded () {
      const map = this.$refs.mapContainer.map
      this.initCircleSelection()
      this.mapReady = true
      this.zoomInit()
      map.on('click', 'provigo', e => {
        const feature = e.features[0]
        this.itemSelected = feature.properties.id
      })
      map.on('mouseenter', 'provigo', e => {
        map.getCanvas().style.cursor = 'pointer'
      })
      map.on('mouseleave', 'provigo', () => {
        map.getCanvas().style.cursor = ''
      })
    },
    initCircleSelection () {
      const map = this.$refs.mapContainer.map
      this.circleSelectionLayer = new CircleSelectionLayer(map, 14)
    },
    select (id) {
      this.radiusToolActive = true
      const el = this.provigoFeatures.find(p => p.properties.id === id)
      const map = this.$refs.mapContainer.map
      const [lng, lat] = el.geometry.coordinates
      this.circleSelection.center = { lng, lat }
      map.flyTo({ center: this.circleSelection.center, zoom: this.circleSelectionLayer.zoom })
    },
    zoomInit () {
      const mapContainer = this.$refs.mapContainer
      if (mapContainer.$el.offsetWidth < 4000) {
        mapContainer.map.flyTo({ center: { lng: -73.76, lat: 44.77 }, zoom: 4 })
        return
      }
      mapContainer.map.fitBounds(turfBbox(this.provigoSource), {
        padding: 400
      })
    }
  }
}
</script>
<style lang="scss">
.panel-lg-screen {
  @media (max-width: 1023px) {
    display: none;
  }
}
.panel-sm-screen {
  @media (min-width: 1024px) {
    display: none;
  }
}
.density-legend {
  margin-bottom: 64px;
  &__widget {
    font-size: 13px;
  }
}
.panel {
  &-header {
    position: relative;
    button {
      position: absolute;
      right: 0;
    }
  }
  &-content {
    padding: 0 16px;
    @media (max-width: 1023px) {
      padding: 0;
    }
    &-element {
      padding: 16px 0;
      @media (max-width: 1023px) {
        padding: 0;
      }
      border-bottom: 1px solid #dfdfdf;
    }
  }
}
</style>
