import Vue from 'vue'
import Vuex from 'vuex'
import selectionStats from './modules/selection-stats'
import auth from '../commons/auth/vuex-module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    selectionStats,
    auth
  }
})
