<template>
  <div class="map-container">
    <div id="map"/>
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl'
import style from '@/style'
import 'mapbox-gl/dist/mapbox-gl.css'
import { buildSrcLayerConfig, mergeConfig } from '@/utils/layer'
const defaultPosition = {
  center: { lng: -73.76, lat: 44.77 }, zoom: 4
}
const defaultOptions = {
  ...defaultPosition,
  container: 'map',
  style: {
    ...style
    // sprite: window.location.origin + process.env.BASE_URL + 'sprites/toledo'
  },
  minZoom: 5,
  maxZoom: 17,
  attributionControl: false,
  hash: false
}
const getLayers = (id, layersets) => {
  return id.split('-')[0] === 'layerset'
    ? layersets.find(layer => layer.id === id).layers
    : [id]
}
export default {
  props: {
    sources: {
      type: Array,
      default: () => []
    },
    layersets: {
      type: Array,
      default: () => []
    },
    layerStyles: {
      type: Array,
      default: () => []
    },
    defaultStyles: {
      type: Object,
      default: () => {}
    },
    visibleLayers: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      loaded: false
    }
  },
  computed: {
    mapVisibleLayers () {
      const visibleLayersFromLayerSet = this.layersets
        .filter(layerset => this.visibleLayers.includes(layerset.id))
        .map(layerset => layerset.layers)
      return [...this.visibleLayers, ...visibleLayersFromLayerSet.flat()]
    }
  },
  created () {
    this.map = null
  },
  mounted () {
    this.map = new mapboxgl.Map(defaultOptions)
    global.mapboxMap = this.map
    this.map.on('load', () => {
      this.sources
        .filter(src => src.url) // vt layers
        .map(src => {
          const layerStyle =
            this.layerStyles.find(item => item.id === src.id) || {}
          const defaultStyle = this.defaultStyles[src.type]
          const layerConfig = mergeConfig(layerStyle, defaultStyle)
          const visibility = this.mapVisibleLayers.find(
            layer => layer === src.id
          )
            ? 'visible'
            : 'none'

          const { id, source, layer } = buildSrcLayerConfig(src, {
            ...layerConfig,
            layout: { ...layerConfig.layout, visibility }
          })
          this.map.addSource(id, source)
          this.map.addLayer(layer, layerStyle.before || 'watername_ocean')
          if (layerStyle.popup) {
            this.map.on('mouseenter', id, e => {
              this.map.getCanvas().style.cursor = 'pointer'
            })

            this.map.on('mouseleave', id, () => {
              this.map.getCanvas().style.cursor = ''
            })
            this.map.on('click', id, e => {
              const formatter = layerStyle.formatter || function (value) { return value }
              new mapboxgl.Popup()
                .setLngLat(e.lngLat)
                .setHTML(
                  `<b>${layerStyle.popupLabel}</b><br>${
                    formatter(e.features[0].properties[layerStyle.popup])
                  }`
                )
                .addTo(this.map)
            })
          }
        })
      this.sources
        .filter(src => src.geojson) // geojson layers
        .map(src => {
          this.map.addSource(src.id, {
            type: 'geojson',
            data: src.geojson
          })
          this.map.addLayer({
            id: src.id,
            source: src.id,
            type: src.type,
            paint: src.paint
          })
        })
      this.map.on('idle', e => {
        this.loaded = true
      })
    })
  },
  watch: {
    loaded (value) {
      this.$emit('loaded', value)
    },
    visibleLayers (newValue, oldValue) {
      const toggleLayersVisibility = (layers, visibility) =>
        layers.map(layer =>
          this.map.setLayoutProperty(layer, 'visibility', visibility)
        )
      const addedLayer = newValue.find(layer => !oldValue.includes(layer))
      const removedLayer = oldValue.find(layer => !newValue.includes(layer))
      if (addedLayer) {
        toggleLayersVisibility(getLayers(addedLayer, this.layersets), 'visible')
      }
      if (removedLayer) {
        toggleLayersVisibility(getLayers(removedLayer, this.layersets), 'none')
      }
    }
    // ...watchers
  }
}
</script>

<style lang="scss" scoped>
#map {
  height: 100vh;
  overflow: hidden;
}
</style>
