<template>
  <div class="categories">
    <multi-filters ref="filters" :items="items" @filter="filter">
      <template v-slot:item="{ item }">
        <category :item="item" :value-to-bar-length="valueToBarLength" :format="format" />
      </template>
    </multi-filters>
    <button
      v-if="filtered"
      class="md-button my-button"
      @click="$refs.filters.reset()"
    >
      voir tout
    </button>
  </div>
</template>

<script>
import MultiFilters from './MultiFilters'
import Category from './AnagraphCategory'
export default {
  components: {
    MultiFilters,
    Category
  },
  props: {
    items: {
      type: Array,
      default: () => [
        { id: 1, color: 'blue', name: 'Résidentielle', value: 40 },
        { id: 2, color: 'green', name: 'Commerciale', value: 18 },
        { id: 3, color: 'yellow', name: 'Bureau', value: 16 }
      ]
    },
    format: {
      type: String,
      default: 'percent'
    }
  },
  data () {
    return {
      filtered: false
    }
  },
  computed: {
    total () {
      return this.items.reduce((acc, current) => {
        acc += current.value
        return acc
      }, 0)
    },
    max () {
      return Math.max(...this.items.map(item => item.value))
    }
  },
  methods: {
    valueToBarLength (value) {
      return (value / this.max) * 100
    },
    filter (filter) {
      if (filter.length !== 0) {
        this.filtered = true
      } else {
        this.filtered = false
      }
      this.$emit('filter', filter.length ? filter : null)
    }
  }
}
</script>

<style scoped>
.my-button {
  width: 80px !important;
  min-width: unset !important;
  height: 26px;
  border-radius: 4px;
  background-color: #e7e7e7;
  margin: 0px;
  margin-top: 10px;
}
</style>
