import * as turf from '@turf/turf'
import promisify from 'map-promisified'

const options = {
  steps: 64
}
class CircleSelection {
  constructor ({ center, radius }) {
    const centerLatLng = [center.lng, center.lat]
    this.buffer = turf.buffer(turf.point(centerLatLng), radius, options)
  }

  getCircleMask () {
    return turf.mask(this.buffer)
  }
}
export default class CircleSelectionLayer {
  constructor (map, zoom) {
    this.map = map
    this.zoom = zoom
    this.center = null
    this.buildLayer()
  }

  buildLayer () {
    this.map.addSource('mask-source', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    })
    this.map.addLayer({
      id: 'mask-layer',
      source: 'mask-source',
      type: 'fill',
      layout: {
        visibility: 'none'
      },
      paint: {
        'fill-color': 'white',
        'fill-opacity': 0.8
      }
    })
  }

  set ({ center, radius }) {
    this.center = center
    const circleSelection = new CircleSelection({ center, radius })
    const maskGeometry = circleSelection.getCircleMask()
    this.map.getSource('mask-source').setData(maskGeometry)
  }

  async updateZoom () {
    const flyToPromisified = promisify(this.map, 'flyTo')
    const zoom = this.zoom
    if (zoom && this.map.getZoom() < zoom) {
      const { lng, lat } = this.center
      await flyToPromisified({ center: [lng, lat], zoom })
    }
  }

  async toggle (visible) {
    this.map.setLayoutProperty(
      'mask-layer',
      'visibility',
      visible ? 'visible' : 'none'
    )
    // if (visible && this.center) await this.updateZoom()
  }
}
