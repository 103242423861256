<template>
  <ul class="multi-filters">
    <li
      v-for="(item, index) in selectableItems"
      :key="index"
      @click="select(item)"
    >
      <slot name="item" :item="item">
        <span
          :class="{ disabled: !item.visible, highlighted: item.highlighted }"
          >{{ item.name }}</span
        >
      </slot>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [
        { id: 1, color: 'blue', name: 'element 1', value: 1 },
        { id: 2, color: 'green', name: 'element 2', value: 2 },
        { id: 3, color: 'yellow', name: 'element 3', value: 3 }
      ]
    }
  },
  data () {
    return {
      selectableItems: this.items.map(item => ({
        ...item,
        visible: true,
        highlighted: false
      }))
    }
  },
  computed: {
    someSelected () {
      return this.selectableItems.some(item => !item.visible)
    }
  },
  watch: {
    items: function (newVal) {
      if (this.selectableItems.length === 0) {
        this.selectableItems = this.items.map(item => ({
          ...item,
          visible: true,
          highlighted: false
        }))
      } else {
        this.selectableItems.forEach(item => {
          const newItem = newVal.find(a => {
            return a.name === item.name
          })
          item.value = newItem ? newItem.value : 0
        })
      }
    }
  },
  methods: {
    reset () {
      for (const item of this.selectableItems) {
        item.visible = true
        item.highlighted = false
      }
      this.$emit('filter', [])
    },
    select (item) {
      if (!this.someSelected) {
        for (const item of this.selectableItems) {
          item.visible = false
        }
      }

      item.visible = !item.visible
      item.highlighted = !item.highlighted
      const filteredNames = this.selectableItems
        .filter(item => item.visible)
        .map(item => item.name)
      this.$emit('filter', filteredNames)
      if (!this.selectableItems.some(item => item.visible)) {
        for (const item of this.selectableItems) {
          item.visible = true
          item.highlighted = false
        }
      }
    }
  }
}
</script>

<style>
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.disabled {
  opacity: 0.3;
}
.highlighted {
  font-weight: 800;
}
</style>
