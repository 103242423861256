<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
    <div class="selected" :class="{ open: open }" @click="open = !open">
      {{ options.find(o => o.id === selected).label }}
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div
        class="item"
        v-for="option of options"
        :key="option.id"
        @click="select(option.id)"
      >
        <div v-html="option.label"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      // required: true,
      default: () => ['Saint-Mathias-sur-Richelieu', 'b', 'c']
    },
    default: {
      type: String,
      required: false,
      default: null
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0
    },
    selected: {
      type: String
    }
  },
  data () {
    return {
      open: false
    }
  },
  methods: {
    select (id) {
      this.$emit('update:selected', id)
      this.open = false
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 47px;
  line-height: 47px;
  font-size: 16px;
  .selected {
    overflow: hidden;
    width: 80%;
    height: 47px;
    background-color: white;
    /* border: 1px solid #dfdfdf; */
    color: black;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
    &:after {
      position: absolute;
      content: "";
      top: 16px;
      right: 20px;
      border: solid black;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 4px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
  .items {
    color: black;
    border-radius: 0px 0px 6px 6px;
    overflow: scroll;
    height: 400px;
    border-right: 1px solid #dfdfdf;
    border-left: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    position: absolute;
    background-color: white;
    left: 0;
    right: 0;
    z-index: 1000;
    &.selectHide {
      display: none;
    }

    .item {
      color: black;
      padding: 0px 16px;
      cursor: pointer;
      user-select: none;
      border-bottom: solid 1px #dfdfdf;
      &:hover {
        background-color: #dfdfdf;
      }
    }
  }
}
</style>
