import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { auth } from '@/commons/auth/firebase'
import makeAuthApp from '@/commons/auth/authController'
Vue.config.productionTip = false
let app
const callback = makeAuthApp(App, app, store, router, 'Login', ['Home'])
auth.onAuthStateChanged(callback)
global.auth = auth
