<template>
  <div class="left-panel">
    <div
      class="panel-icon panel-arrow"
      :class="{ hide: !active }"
      @click="active = !active"
    >
      <i class="fas" :class="`fa-arrow-${active ? 'left' : 'right'}`"></i>
    </div>
    <div v-show="active" class="left-panel-container">
      <header><slot name="header">header</slot></header>
      <main><slot name="main">content</slot></main>
      <footer><slot name="footer">footer</slot></footer>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      active: true
    }
  },
  computed: {
    layersToggleVisible () {
      return this.$store.state.filters ? this.$store.state.filters.visibleLayers.length : true
    }
  }
}
</script>

<style lang="scss" scoped>
.left-panel {
  position: absolute;
  &-container {
    background-color: white;
    width: 360px;
    position: relative;
    text-align: left;
    z-index: 1;
    float: left;
    box-shadow: 0 4px 18px -6px rgba(0, 0, 0, 0.26);
    top: 50px;
    left: 50px;
    min-height: 245px;
    max-height: 730px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* overflow: auto; */
    /* header {
      overflow: ;
    } */
    main {
      height: 700px;
      overflow: scroll;
    }
  }
  .panel-icon {
    height: 30px;
    width: 30px;
    box-shadow: -4px 4px 7px -3px rgba(0, 0, 0, 0.18);
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 2;
    left: 430px;
    &.hide {
      left: 0px;
    }
  }
  .panel-arrow {
    top: 65px;
    border-radius: 4px;
  }
}
</style>
