<template>
  <div class="category" :style="{ opacity: item.visible ? 1 : 0.3 }">
    <div class="labels">
      <div class="legend">
        <div
          v-if="item.color"
          class="point"
          :style="{ 'background-color': item.color }"
        />
        <div class="name">{{ item.name }}</div>
      </div>
      <div class="value">{{ formattedValue }}</div>
    </div>
    <progress-bar
      :bar-color="customStyle.barColor"
      :val="valueToBarLength(item.value)"
      size="2"
    />
  </div>
</template>

<script>
import ProgressBar from 'vue-simple-progress'
export default {
  components: {
    ProgressBar
  },
  props: {
    item: {
      type: Object,
      default: () => ({
        name: 'category',
        color: 'blue',
        value: 50
      })
    },
    valueToBarLength: {
      type: Function,
      default: value => value
    },
    customStyle: {
      type: Object,
      default: () => ({
        barColor: 'grey'
      })
    },
    format: {
      type: String,
      default: 'percent'
    }
  },
  computed: {
    formattedValue () {
      return isNaN(this.item.value)
        ? '-'
        : (Math.round(this.item.value * 10) / 10) + (this.format === 'percent' ? ' %' : '')
    }
  }
}
</script>

<style lang="scss" scoped>
.category {
  margin-bottom: 8px;
}
.labels {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
  font-size: 14px;
  .legend {
    display: inline-flex;
    align-items: center;
    .point {
      width: 13px;
      height: 13px;
      border-radius: 50%;
      margin-right: 17px;
    }
  }
}
</style>
