<template>
  <div class="right-panel">
    <div v-show="active" class="right-panel-container">
      <slot>right-panel-content</slot>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      active: true
    }
  },
  computed: {
    layersToggleVisible () {
      return this.$store.state.filters ? this.$store.state.filters.visibleLayers.length : true
    }
  }
}
</script>

<style lang="scss" scoped>
.right-panel {
  position: absolute;
  top: 50px;
  right: 50px;
  &-container {
    background-color: white;
    width: 320px;
    position: relative;
    text-align: left;
    overflow: auto;
    z-index: 1;
    float: left;
    box-shadow: 0 4px 18px -6px rgba(0, 0, 0, 0.26);
    min-height: 245px;
    max-height: 800px;
    padding: 20px;
    @media (max-width: 1023px) {
      min-height: 0;
      width: inherit;
      padding: 0px;
      background-color: transparent;
    }
  }
}
</style>
