import provigoGeoJSON from './provigo'
const ids = ['7119',
  '7195',
  '7294',
  '8403',
  '8445',
  '8450',
  '8451',
  '8453',
  '8456',
  '8464',
  '8536',
  '8595',
  '8613',
  '8642',
  '8802',
  '8807',
  '8808',
  '8812',
  '8827',
  '8896',
  '8682',
  '8455'
]
export default [
  {
    id: 'provigo',
    geojson: { ...provigoGeoJSON, features: provigoGeoJSON.features.filter(f => ids.includes(f.properties.id)) },
    type: 'circle',
    paint: {
      'circle-radius': 4,
      'circle-opacity': 0.8,
      'circle-stroke-width': 2,
      'circle-stroke-color': '#E1221C',
      'circle-color': '#F28900'
    },
    layout: {}
  },
  {
    id: 'densite-de-population',
    url: 'https://pilote.anagraph.io/toledo/dev/t/public.densite',
    type: 'fill'
  }
]
