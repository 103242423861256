import firebase from 'firebase/app'
import 'firebase/auth'
import { firebase as fbConfig } from '@/config'

if (!firebase.apps.length) {
  firebase.initializeApp(fbConfig)
}
const auth = firebase.auth()
export {
  auth
}
