export function buildSrcLayerConfig (src, layerConfig = {}) {
  const sourceLayer = src.url.split('/').pop()
  const id = src.id
  const source = {
    type: 'vector',
    tiles: [`${src.url}/{z}/{x}/{y}.pbf`]
  }
  const layer = {
    id,
    type: src.type,
    source: id,
    'source-layer': sourceLayer,
    ...layerConfig
  }
  return { id, source, layer }
}

export function mergeConfig (layerStyle, defaultStyle) {
  const {
    layout: layerLayout,
    paint: layerPaint,
    filter: layerFilter
  } = layerStyle
  const defaultLayout = defaultStyle.layout
  const layout = {
    ...defaultLayout,
    ...layerLayout
  }
  const defaultPaint = defaultStyle.paint
  const paint = {
    ...defaultPaint,
    ...layerPaint
  }
  const filterConfig = layerFilter ? { filter: layerFilter } : {}
  return {
    layout,
    paint,
    ...filterConfig
  }
}
