import ApiClient from './api-client'
export default class geoDbServiceClient {
  constructor (token, dataset) {
    this.client = new ApiClient(token, process.env.VUE_APP_GEOSERVICE_URL)
    this.dataset = dataset
  }

  async fetchStats (sqlFunctionsParams = []) {
    const response = await this.client.post('fetchStats', sqlFunctionsParams)
    return response.data
  }
}
