import palette from '@/config/populationPalette'
const stepsPalette = palette.map(item => [item.min, item.color]).flat()
export default [
  {
    id: 'densite-de-population',
    // layout: ,
    paint: {
      'fill-color': [
        'step',
        ['get', 'r6'],
        'white',
        ...stepsPalette
        // 0,
        // '#F2F12D',
        // 1000,
        // '#EED322',
        // 2500,
        // '#E6B71E',
        // 5000,
        // '#DA9C20',
        // 7500,
        // '#CA8323',
        // 10000,
        // '#B86B25'
      ],
      'fill-opacity': 0.5
    },
    before: 'road_minor_case'
  }
]
