<template>
  <div>
    <div v-for="(color, index) in formattedItems" :key="index" class="color">
      <div
        class="color-rectangle"
        :style="{ 'background-color': color.color }"
      ></div>
      <div v-if="color.min && color.max">{{ color.min }} à {{ color.max }}</div>
      <div v-if="!color.max">plus de {{ color.min }}</div>
      <div v-if="!color.min">moins de {{ color.max }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [
        { min: 0, max: 1000, color: 'blue' },
        { min: 1000, max: 2000, color: 'chartreuse' },
        { min: 2000, max: 3000, color: 'tomato' },
        { min: 3000, color: 'hotpink' }
      ]
    }
  },
  computed: {
    formattedItems () {
      return this.items.map(({ min, max, color }) => ({
        min: min ? new Intl.NumberFormat('fr-CA').format(min) : min,
        max: max ? new Intl.NumberFormat('fr-CA').format(max) : max,
        color
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
.color {
  display: flex;
  &-rectangle {
    width: 16px;
    height: 16px;
    border-radius: 5px;
    margin-right: 13px;
    margin-bottom: 10px;
  }
}
</style>
